<div class="filters-wrapper">
  <div class="filters-indicators" *ngIf="pagination$ | async as pagination">
    <span
      *ngIf="pagination.onlyAssignedToMe"
      class="filter-indicator indicator-only-assigned-to-me"
      nz-icon
      nzType="user-switch"
      nz-tooltip
      [nzTooltipPlacement]="'left'"
      nzTooltipTitle="{{ 'NT3.SHOWING_ONLY_ASSIGNED_TO_ME' | translate }}"
      nzTheme="outline">
    </span>
    <span
      *ngIf="pagination.orderBy === SupportChatsOrder.LAST_MESSAGE_DATE"
      class="filter-indicator indicator-sort-by"
      nz-icon
      nzType="field-time"
      nz-tooltip
      [nzTooltipPlacement]="'top'"
      nzTooltipTitle="{{ 'NT3.SORTING_BY_MESSAGE_DATE' | translate }}"
      nzTheme="outline">
    </span>
    <span
      *ngIf="pagination.orderBy === SupportChatsOrder.USER_NAME"
      class="filter-indicator indicator-sort-by"
      nz-icon
      nzType="sort-ascending"
      nz-tooltip
      [nzTooltipPlacement]="'top'"
      nzTooltipTitle="{{ 'NT3.SORTING_ALPHABETICALLY' | translate }}"
      nzTheme="outline">
    </span>
    <span
      *ngIf="pagination.supportedUserName"
      class="filter-indicator indicator-search"
      nz-icon
      nzType="search"
      nz-tooltip
      [nzTooltipPlacement]="'bottom'"
      nzTooltipTitle="{{ 'NT3.SEARCH_ACTIVE' | translate }}"
      nzTheme="outline">
    </span>
  </div>
  <button
    nz-button
    nz-dropdown
    nzShape="round"
    nzSize="small"
    nzTrigger="hover"
    (click)="toggleFiltersListDrawer()"
    [nzPlacement]="'bottomCenter'"
    class="filters-button">
    <span nz-icon nzType="filter" nzTheme="outline"></span>
  </button>
</div>

<ng-template #indicatorTemplate><span nz-icon nzType="loading"></span></ng-template>
<nz-spin
  [nzIndicator]="indicatorTemplate"
  [nzDelay]="10"
  [nzSpinning]="loadingChatsList$ | async"
  class="main-support-chats-list-loading-indicator">
  <div class="list" #chatsList>
    <div
      *ngFor="let supportChat of supportChatsList$ | async"
      class="item"
      (click)="openSupportChat(supportChat)"
      [class.open]="supportChat.id === (openSupportChat$ | async)?.supportChat?.id"
      [class.unread]="supportChat.hasUnreadMessages"
      [class.attention-required]="supportChat.marker === SupportChatMarker.ATTENTION_REQUIRED"
      [class.already-handled]="supportChat.marker === SupportChatMarker.ALREADY_HANDLED"
      [class.comeback-later]="supportChat.marker === SupportChatMarker.COMEBACK_LATER">
      <div class="column">
        <div class="avatar">
          <nz-spin
            [nzIndicator]="indicatorTemplate"
            [nzSpinning]="(loadingSupportChatUpdate$ | async) === supportChat.id"
            class="chat-item-avatar-loading-indicator">
            <mat-icon fontSet="material-icons-outlined" *ngIf="!supportChat.supportedUser.avatarUpdatedAt"
              >account_circle
            </mat-icon>
            <img
              [src]="API_URL + '/user-assets/avatar/' + supportChat.supportedUser.id"
              *ngIf="supportChat.supportedUser.avatarUpdatedAt"
              alt="User avatar" />
          </nz-spin>
        </div>
        <div class="name">
          {{ supportChat.supportedUser?.name || 'MISSING USERNAME' }}
        </div>
        <!-- <div class="last-message" *ngIf="supportChat.lastMessageAt">
          {{ supportChat.lastMessageAt | date : 'dd-MM-YYYY HH:mm'}}
        </div> -->
      </div>
      <div class="column">
        <ng-container [ngSwitch]="supportChat.marker">
          <mat-icon
            *ngSwitchCase="SupportChatMarker.ATTENTION_REQUIRED"
            class="marker-icon"
            fontSet="material-icons-outlined">
            priority_high
          </mat-icon>
          <mat-icon
            *ngSwitchCase="SupportChatMarker.COMEBACK_LATER"
            class="marker-icon"
            fontSet="material-icons-outlined">
            schedule
          </mat-icon>
          <mat-icon
            *ngSwitchCase="SupportChatMarker.ALREADY_HANDLED"
            class="marker-icon"
            fontSet="material-icons-outlined">
            check_circle
          </mat-icon>
        </ng-container>

        <button
          nz-button
          nz-dropdown
          nzType="text"
          nzShape="round"
          nzSize="small"
          nzTrigger="click"
          (click)="$event.stopPropagation()"
          [nzDropdownMenu]="itemMarkersMenu"
          [nzPlacement]="'bottomLeft'"
          [nzClickHide]="true"
          class="markers-dropdown-trigger">
          <mat-icon fontSet="material-icons-outlined">more_horiz</mat-icon>
        </button>
        <nz-dropdown-menu #itemMarkersMenu="nzDropdownMenu">
          <ul nz-menu>
            <li
              nz-menu-item
              class="attention-marker-item"
              (click)="addSupportChatMarker(SupportChatMarker.ATTENTION_REQUIRED, supportChat.id)">
              <mat-icon class="marker-icon icon-attention" fontSet="material-icons-outlined">priority_high</mat-icon>
              <span>{{ 'NT3.MARK_ATTENTION_REQUIRED' | translate }}</span>
            </li>
            <li
              nz-menu-item
              class="attention-marker-item"
              (click)="addSupportChatMarker(SupportChatMarker.COMEBACK_LATER, supportChat.id)">
              <mat-icon class="marker-icon icon-later" fontSet="material-icons-outlined">schedule</mat-icon>
              <span>{{ 'NT3.MARK_AS_COMEBACK_LATER' | translate }}</span>
            </li>
            <li
              nz-menu-item
              class="attention-marker-item"
              (click)="addSupportChatMarker(SupportChatMarker.ALREADY_HANDLED, supportChat.id)">
              <mat-icon class="marker-icon icon-done" fontSet="material-icons-outlined">check_circle</mat-icon>
              <span>{{ 'NT3.MARK_AS_DONE' | translate }}</span>
            </li>
            <li nz-menu-item class="attention-marker-item" (click)="markAsUnread(supportChat.id)">
              <mat-icon class="marker-icon icon-grey" fontSet="material-icons-outlined">visibility_off</mat-icon>
              <span>{{ 'NT3.MARK_AS_UNREAD' | translate }}</span>
            </li>
            <li nz-menu-item class="attention-marker-item" (click)="openFollowupDialogChat(supportChat)">
              <mat-icon class="marker-icon icon-grey" fontSet="material-icons-outlined">chat</mat-icon>
              <span>{{ 'FOLLOW_UP.FOLLOW_UP_CHAT' | translate }}</span>
            </li>
            <li nz-menu-item class="attention-marker-item" (click)="openFollowupDialogEmail(supportChat)">
              <mat-icon class="marker-icon icon-grey" fontSet="material-icons-outlined">mail</mat-icon>
              <span>{{ 'FOLLOW_UP.FOLLOW_UP_EMAIL' | translate }}</span>
            </li>
            <li nz-menu-item class="attention-marker-item" (click)="getTranscription(supportChat)">
              <mat-icon class="marker-icon icon-grey" fontSet="material-icons-outlined">download</mat-icon>
              <span>{{ 'MANAGEMENT.CHAT.GET_TRANSCRIPTION' | translate }}</span>
            </li>
            <li
              nz-menu-item
              class="attention-marker-item"
              [routerLink]="['/management/foreigner-details', supportChat.supportedUserId]"
              [queryParams]="{ tabIndex: 0 }">
              <mat-icon class="marker-icon icon-grey" fontSet="material-icons-outlined">person</mat-icon>
              <span>{{ 'NT3.GO_TO_FOREIGNER_DETAILS' | translate }}</span>
            </li>
            <li
              nz-menu-item
              class="attention-marker-item"
              [routerLink]="['/management/foreigner-details', supportChat.supportedUserId]"
              [queryParams]="{ tabIndex: 2 }">
              <mat-icon class="marker-icon icon-grey" fontSet="material-icons-outlined">folder_open</mat-icon>
              <span>{{ 'NT3.GO_TO_FOREIGNER_FILES' | translate }}</span>
            </li>
          </ul>
        </nz-dropdown-menu>
      </div>
    </div>
    <div class="item last-item">
      <mat-spinner [diameter]="35" *ngIf="loadingMoreChats$ | async"></mat-spinner>
      <div class="no-more-items-message" *ngIf="allChatsLoaded">
        {{ 'NT3.NO_MORE_SUPPORT_CHATS' | translate }}
      </div>
    </div>
  </div>
</nz-spin>

<nz-drawer
  [nzClosable]="false"
  [nzVisible]="showFiltersListDrawer"
  [nzPlacement]="'bottom'"
  [nzHeight]="'200px'"
  [nzZIndex]="1002"
  [nzBodyStyle]="{
    padding: '0'
  }">
  <ng-container *nzDrawerContent>
    <div class="chat-filters-button" (click)="toggleFiltersListDrawer()">
      <span nz-icon nzType="close" nzTheme="outline"></span>
    </div>
    <div class="chat-filters">
      <div class="filter-item" style="display: flex; justify-content: space-between; align-items: center; width: 99%">
        <p style="margin: 0">{{ 'NT3.ONLY_ASSIGNED_TO_ME' | translate }}</p>
        <nz-switch
          [ngModel]="(pagination$ | async)?.onlyAssignedToMe"
          [nzLoading]="loadingChatsList$ | async"
          (ngModelChange)="searchSupportChats({ onlyAssignedToMe: $event })"
          nzSize="small">
        </nz-switch>
      </div>
      <div class="filter-item" style="display: flex; justify-content: space-between; align-items: center; width: 99%">
        <nz-radio-group
          [ngModel]="(pagination$ | async)?.orderBy"
          (ngModelChange)="searchSupportChats({ orderBy: $event })"
          nzButtonStyle="solid"
          nzSize="small"
          style="width: 100%">
          <label nz-radio-button [nzValue]="SupportChatsOrder.LAST_MESSAGE_DATE" style="width: 50%; text-align: center">
            {{ 'NT3.ORDER_BY_LAST_MESSAGE_DATE' | translate }}
          </label>
          <label nz-radio-button [nzValue]="SupportChatsOrder.USER_NAME" style="width: 50%; text-align: center">
            {{ 'NT3.ORDER_BY_USER_NAME' | translate }}
          </label>
        </nz-radio-group>
      </div>
      <div
        class="filter-item filter-input-text"
        style="display: flex; justify-content: space-between; align-items: center; width: 99%">
        <nz-input-group [nzSuffix]="suffixIconSearch" nzSize="small">
          <input
            type="text"
            nz-input
            [ngModel]="(pagination$ | async)?.supportedUserName || ''"
            (ngModelChange)="debouncedSearch({ supportedUserName: $event })"
            placeholder="{{ 'NT3.SEARCH_BY_NAME' | translate }}"
            style="min-width: 200px" />
        </nz-input-group>
        <ng-template #suffixIconSearch>
          <span nz-icon nzType="search" *ngIf="!(pagination$ | async)?.supportedUserName"> </span>
          <span
            nz-icon
            class="ant-input-clear-icon"
            nzTheme="fill"
            nzType="close-circle"
            *ngIf="(pagination$ | async)?.supportedUserName"
            (click)="searchSupportChats({ supportedUserName: null })"></span>
        </ng-template>
      </div>
    </div>
  </ng-container>
</nz-drawer>
