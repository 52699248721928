import { Action, createReducer, on } from '@ngrx/store';
import * as dcActions from './discount-codes.actions';
import { DiscountCodesState, initialState } from './discount-codes.state';

const reducer = createReducer(
  initialState,

  on(
    dcActions.createDiscountCode,
    dcActions.editDiscountCode,
    dcActions.getAllDiscountCodes,
    dcActions.setActiveDiscountCode,
    state => ({
      ...state,
      loading: true,
    })
  ),
  on(dcActions.createDiscountCodeSuccess, dcActions.editDiscountCodeSuccess, (state, { discountCode }) => ({
    ...state,
    loading: false,
    allDiscountCodes: [structuredClone(discountCode), ...(state.allDiscountCodes || [])],
  })),
  on(dcActions.getAllDiscountCodesSuccess, (state, { discountCodes }) => ({
    ...state,
    loading: false,
    allDiscountCodes: [...discountCodes],
  })),
  on(dcActions.setActiveDiscountCodeSuccess, (state, { discountCode }) => ({
    ...state,
    loading: false,
    allDiscountCodes: state.allDiscountCodes.map(dc =>
      dc.id === discountCode.id ? structuredClone(discountCode) : dc
    ),
  })),
  on(
    dcActions.createDiscountCodeError,
    dcActions.editDiscountCodeError,
    dcActions.getAllDiscountCodesError,
    dcActions.setActiveDiscountCodeError,
    state => ({
      ...state,
      loading: false,
    })
  )
);

export function discountCodesReducer(state: DiscountCodesState | undefined, action: Action): DiscountCodesState {
  return reducer(state, action);
}
