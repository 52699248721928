<div class="title-container">
  <h2 mat-dialog-title>
    {{ 'CHANGES_HISTORY_MODAL.USER-PROCESS-CHANGES-HISTORY' | translate }}
    {{ data.userProcess.name }}
  </h2>
  <button mat-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content class="mat-typography" #dialogContent>
  <table
    mat-table
    matSort
    (matSortChange)="sortData($event)"
    [matSortActive]="currentSortBy"
    [matSortDirection]="currentSortDir"
    [dataSource]="changesList">
    <ng-container matColumnDef="index">
      <th mat-header-cell *matHeaderCellDef>{{ 'CHANGES-LIST.NR_OF_CHANGE' | translate }}</th>
      <td mat-cell *matCellDef="let change; let changeIndex = index">{{ changesList.length - changeIndex }}.</td>
    </ng-container>

    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef [disableClear]="true" mat-sort-header="createdAt">
        {{ 'CHANGES-LIST.DATE' | translate }}
      </th>
      <td mat-cell *matCellDef="let change">{{ change.createdAt | date: 'dd.MM.YYYY HH:mm' }}</td>
    </ng-container>
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef [disableClear]="true" mat-sort-header="type">
        {{ 'CHANGES-LIST.TYPE-CHANGE' | translate }}
      </th>
      <td mat-cell *matCellDef="let change">
        <span
          class="change-text"
          [class.positive]="positiveChanges.includes(change.type)"
          [class.negative]="negativeChanges.includes(change.type)"
          [innerHTML]="'CHANGES-LIST.TYPE.' + change.type | translate">
        </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="changeAuthor">
      <th mat-header-cell *matHeaderCellDef>{{ 'CHANGES-LIST.AUTHOR' | translate }}</th>
      <td mat-cell *matCellDef="let change">
        <ng-container *ngIf="change.changeAuthor; else noAuthor">
          {{ change.changeAuthor.name || change.changeAuthor.email }}
        </ng-container>
        <ng-template #noAuthor> - </ng-template>
      </td>
    </ng-container>
    <ng-container matColumnDef="details">
      <th mat-header-cell *matHeaderCellDef>{{ 'CHANGES-LIST.DETAILS' | translate }}</th>
      <td mat-cell *matCellDef="let change">
        <ng-container *ngIf="change.details?.reason">
          {{ 'CHANGES-LIST.REASON-OF-CHANGE' | translate }}
          <b>{{ 'CHANGES-LIST.REASONS.' + change.details.reason | translate }}</b>
        </ng-container>
        <ng-container *ngIf="change.details?.newStatus as newStatus">
          <span class="positive-name">{{ 'CHANGES_HISTORY_MODAL.SET-TO-NEW-STATUS' | translate }}</span>
          <ng-container *ngIf="!data.userProcess.hasAssistantService && !data.userProcess.hasFullHandling">
            {{ 'STATUS_BY_PURCHASED_SERVICE.BASIC_PROCESS_STATUS.' + newStatus | translate }}
          </ng-container>
          <ng-container *ngIf="data.userProcess.hasAssistantService && !data.userProcess.hasFullHandling">
            {{ 'STATUS_BY_PURCHASED_SERVICE.ASSISTANT_PROCESS_STATUS.' + newStatus | translate }}
          </ng-container>
          <ng-container *ngIf="data.userProcess.hasAssistantService && data.userProcess.hasFullHandling">
            {{ 'STATUS_BY_PURCHASED_SERVICE.FULL_PROCESS_STATUS.' + newStatus | translate }}
          </ng-container>
          <br />
        </ng-container>
        <ng-container *ngIf="change.details?.oldStatus as oldStatus">
          <span class="error-name">{{ 'CHANGES-LIST.OLD_STATUS' | translate }}</span>
          <ng-container *ngIf="!data.userProcess.hasAssistantService && !data.userProcess.hasFullHandling">
            {{ 'STATUS_BY_PURCHASED_SERVICE.BASIC_PROCESS_STATUS.' + oldStatus | translate }}
          </ng-container>
          <ng-container *ngIf="data.userProcess.hasAssistantService && !data.userProcess.hasFullHandling">
            {{ 'STATUS_BY_PURCHASED_SERVICE.ASSISTANT_PROCESS_STATUS.' + oldStatus | translate }}
          </ng-container>
          <ng-container *ngIf="data.userProcess.hasAssistantService && data.userProcess.hasFullHandling">
            {{ 'STATUS_BY_PURCHASED_SERVICE.FULL_PROCESS_STATUS.' + oldStatus | translate }}
          </ng-container>
          <br />
        </ng-container>
        <ng-container *ngIf="change.details?.opts?.withCredentials as credentials">
          <div
            class="details-data-wrapper account-used-info"
            [innerHTML]="'NT2.ACCOUNT_USED' | translate: { account: credentials.email }"></div>
        </ng-container>
        <div class="error-cell" *ngIf="change.details?.error">
          <ng-container *ngIf="change.details?.error?.name">
            <span class="error-name">Error: {{ change.details?.error.name }}</span>
          </ng-container>
          <ng-container *ngIf="change.details?.error?.message">
            <span class="error-name">Error: {{ change.details.error.message }}</span>
          </ng-container>
          <ng-container *ngIf="change.details?.error?.credentialsInvalid">
            <span class="error-name">{{ 'NT.INVALID_CREDENTIALS' | translate }}</span>
          </ng-container>
          <ng-container *ngIf="change.details?.error?.length">
            <span class="error-name">{{ change.details?.error }}</span>
          </ng-container>
          <ng-container *ngIf="change.details?.error?.screenshotPath">
            <button
              class="screenshot-error-button"
              (click)="openErrorScreenshot(change.details?.error.screenshotPath)"
              mat-stroked-button
              color="warn">
              {{ 'CHANGES_HISTORY_MODAL.SHOW-SCREENSHOT-OF-ERROR' | translate }}
            </button>
          </ng-container>
          <ng-container *ngIf="change.details?.error?.recordingPath">
            <button
              class="screenshot-error-button"
              (click)="openINPOLRecording(change.details.error.recordingPath)"
              mat-stroked-button
              color="warn">
              {{ 'NT.WATCH-RECORDING' | translate }}
            </button>
          </ng-container>
        </div>
        <div class="details-data-wrapper" *ngIf="change.details?.visitDate">
          <ng-container *ngIf="change.details.visitDate.includes('T'); else NoFormatDate">
            {{ 'CHANGES-LIST.VISIT-DATE' | translate }}:
            <b>{{ change.details?.visitDate | date: 'dd-MM-YYYY HH:mm' }}</b>
          </ng-container>
          <ng-template #NoFormatDate>
            {{ 'CHANGES-LIST.VISIT-DATE' | translate }}:
            <b>{{ change.details?.visitDate }}</b>
          </ng-template>
        </div>
        <div class="details-data-wrapper" *ngIf="change.details?.visitTime">
          {{ 'CHANGES-LIST.VISIT-TIME' | translate }}: <b>{{ change.details?.visitTime }}</b>
        </div>
        <div class="details-data-wrapper" *ngIf="change.details?.visitPlace">
          {{ 'CHANGES-LIST.VISIT-PLACE' | translate }}: <b>{{ change.details?.visitPlace }}</b>
        </div>
        <div class="details-data-wrapper" *ngIf="change.details?.oldVisitAssistant?.name">
          <span class="error-name"> {{ 'CHANGES-LIST.OLD_VISIT_ASSISTANT' | translate }}: </span>
          {{ change.details?.oldVisitAssistant.name }}
        </div>
        <div class="details-data-wrapper" *ngIf="change.details?.newVisitAssistant?.name">
          <span class="positive-name">{{ 'CHANGES-LIST.NEW_VISIT_ASSISTANT' | translate }}: </span>
          <b>{{ change.details?.newVisitAssistant.name }}</b>
        </div>
        <div
          class="details-data-wrapper"
          *ngIf="change.type === 'CHANGE_ITEM_IN_CUSTOM_INSTRUCTION_ITEMS_LIST' && change.details?.instructionItem">
          {{ (change.details?.newVal ? 'NT.ADDED_TO_LIST' : 'NT.REMOVED_FROM_LIST') | translate }}
          <b>{{ change.details.instructionItem.name[currLang] }}</b>
        </div>
        <div class="details-data-wrapper" *ngIf="change.type === 'CHANGED_USING_CUSTOM_INSTRUCTION_ITEMS_LIST'">
          <span
            [innerHTML]="
              (change.details?.newVal ? 'NT.USING_CUSTOM_LIST' : 'NT.USING_DEFAULT_LIST') | translate
            "></span>
        </div>
        <div class="details-data-wrapper" *ngIf="change.type === 'CHANGED_SHOW_FEES_ON_INSTRUCTIONS_LIST'">
          <span [innerHTML]="(change.details?.newVal ? 'NT.FEES_DISPLAYED' : 'NT.FEES_HIDDEN') | translate"></span>
        </div>

        <div class="details-data-wrapper" *ngIf="change.details?.MOSDocumentId">
          <span> MOS DOC ID: {{ change.details.MOSDocumentId }} </span>
        </div>
        <div class="details-data-wrapper" *ngIf="change.details?.userAssetId">
          <button mat-stroked-button (click)="downloadAsset(change.details.userAssetId)">
            {{ 'APPLICATION.OPEN-FILE' | translate }}
          </button>
        </div>
        <div class="details-data-wrapper enabled-services" *ngIf="change.details?.enabledServices?.length">
          <div [innerHTML]="'CHANGES-LIST.SERVICES_ENABLED' | translate"></div>
          <ul>
            <li *ngFor="let serviceName of change.details.enabledServices">
              {{ 'CHANGES-LIST.SERVICE_NAME.' + serviceName | translate }}
            </li>
          </ul>
        </div>
        <div class="details-data-wrapper disabled-services" *ngIf="change.details?.disabledServices?.length">
          <div [innerHTML]="'CHANGES-LIST.SERVICES_DISABLED' | translate"></div>
          <ul>
            <li *ngFor="let serviceName of change.details.disabledServices">
              {{ 'CHANGES-LIST.SERVICE_NAME.' + serviceName | translate }}
            </li>
          </ul>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let change; columns: columnsToDisplay" class="element-row"></tr>
  </table>
</mat-dialog-content>

<mat-dialog-actions> </mat-dialog-actions>
