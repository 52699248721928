import { Injectable } from '@angular/core';
import { CreateDiscountCodeOpts, EditDiscountCodeOpts } from '@interfaces';
import { Actions, ofType } from '@ngrx/effects';

import { Store } from '@ngrx/store';

import { AppState } from '@state';
import * as dcActions from './discount-codes.actions';
import * as dcSelectors from './discount-codes.selectors';

@Injectable()
export class DiscountCodesFacade {
  public allDiscountCodes$ = this.store.select(dcSelectors.selectAllDiscountCodes);
  public loading$ = this.store.select(dcSelectors.selectLoading);

  public createDiscountCodeError$ = this.actions.pipe(ofType(dcActions.createDiscountCodeError));
  public createDiscountCodeSuccess$ = this.actions.pipe(ofType(dcActions.createDiscountCodeSuccess));
  public editDiscountCodeError$ = this.actions.pipe(ofType(dcActions.editDiscountCodeError));
  public editDiscountCodeSuccess$ = this.actions.pipe(ofType(dcActions.editDiscountCodeSuccess));

  public dcError$ = this.actions.pipe(
    ofType(dcActions.createDiscountCodeError, dcActions.setActiveDiscountCodeError, dcActions.getAllDiscountCodesError)
  );

  constructor(
    private store: Store<AppState>,
    private actions: Actions
  ) {}

  public getAllDiscountCodes(showInactive?: boolean): void {
    this.store.dispatch(dcActions.getAllDiscountCodes({ showInactive }));
  }
  public createDiscountCode(opts: CreateDiscountCodeOpts): void {
    this.store.dispatch(dcActions.createDiscountCode({ opts }));
  }
  public editDiscountCode(opts: EditDiscountCodeOpts): void {
    this.store.dispatch(dcActions.editDiscountCode({ opts }));
  }
  public setActiveDiscountCode(discountCodeId: string, setActive: 'deactivate' | 'activate'): void {
    this.store.dispatch(dcActions.setActiveDiscountCode({ discountCodeId, setActive }));
  }
}
