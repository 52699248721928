<div class="delete-account-modal">
  <div class="close-button-wrapper"><mat-icon class="close-button" (click)="close()">close</mat-icon></div>
  <div class="header">{{ 'DELETE_ACCOUNT_MODAL.DELETING_ACCOUNT' | translate }}</div>
  <div class="text">
    {{ 'DELETE_ACCOUNT_MODAL.DELETING_ACCOUNT_DESCRIPTION' | translate }}
  </div>
  <div class="authorization-text">{{ 'DELETE_ACCOUNT_MODAL.DELETING_ACCOUNT_AUTHORIZATION' | translate }}</div>

  <form [formGroup]="form">
    <nz-form-item>
      <nz-form-control [nzSm]="12" [nzXs]="24">
        <input nz-input placeholder="Password" id="password" type="password" formControlName="password" required />
      </nz-form-control>
    </nz-form-item>
  </form>
  <div class="error" *ngIf="passwordError">{{ 'VALIDATORS.' + passwordError | translate }}</div>
  <div class="submit">
    <button [disabled]="!form.valid" (click)="confirm()" mat-raised-button class="submit-button" color="warn">
      {{ 'COMMON.REMOVE' | translate }}
    </button>
  </div>
  <div class="close">
    <button (click)="close()" mat-raised-button class="close-button" color="primary">
      {{ 'COMMON.CANCEL' | translate }}
    </button>
  </div>
</div>
