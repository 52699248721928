import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { CreateDiscountCodeOpts, DiscountCode, EditDiscountCodeOpts } from '@interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DiscountCodesHttpService {
  private API_URL = environment.API_URL;
  private DISCOUNT_CTRL_URL = `${this.API_URL}/discount-codes`;

  constructor(private http: HttpClient) {}

  public createDiscountCode(opts: CreateDiscountCodeOpts): Observable<DiscountCode> {
    const url = this.DISCOUNT_CTRL_URL;
    return this.http.post<DiscountCode>(url, opts);
  }

  public editDiscountCode(opts: EditDiscountCodeOpts): Observable<DiscountCode> {
    const url = `${this.DISCOUNT_CTRL_URL}/${opts.id}`;
    return this.http.patch<DiscountCode>(url, opts);
  }

  public getAllDiscountCodes(showInactive?: boolean): Observable<DiscountCode[]> {
    if (!showInactive) {
      showInactive = false;
    }

    const params = new HttpParams().set('showInactive', showInactive.toString());

    const url = this.DISCOUNT_CTRL_URL;
    return this.http.get<DiscountCode[]>(url, { params });
  }

  public setActiveDiscountCode(discountCodeId: string, setActive: 'deactivate' | 'activate'): Observable<DiscountCode> {
    const url = `${this.DISCOUNT_CTRL_URL}/${discountCodeId}/${setActive}`;
    return this.http.patch<DiscountCode>(url, {});
  }
}
