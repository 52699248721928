import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { CommentsHttpService } from '@core/http/comments.http.service';
import { DocumentComment, ROLES_KEYS } from '@interfaces';
import { parseError } from '@state/errors.parser';
import { UserAuthFacade } from '@state/user-auth';

import { highEmployeeRoles } from '@constants';
import * as commentsActions from './comments.actions';

@Injectable()
export class CommentsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly commentsHttp: CommentsHttpService,
    private readonly userAuthFacade: UserAuthFacade
  ) {}

  getDocumentComments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(commentsActions.getDocumentComments),
      withLatestFrom(this.userAuthFacade.myself$),
      switchMap(([{ userDocumentId, userProcessId }, myself]) => {
        let stream: Observable<DocumentComment[]>;

        if (myself.role.key === ROLES_KEYS.Foreigner) {
          stream = this.commentsHttp.getDocumentCommentsForeigner(userProcessId, userDocumentId);
        } else {
          stream = this.commentsHttp.getDocumentCommentsEmployee(userDocumentId, userProcessId);
        }

        return stream.pipe(
          map(documentComments => {
            return commentsActions.getDocumentCommentsSuccess({ documentComments, userDocumentId, userProcessId });
          }),
          catchError(error => {
            const errorMessage = parseError(error, commentsActions.getDocumentComments.type);
            return of(commentsActions.getDocumentCommentsError({ errorMessage }));
          })
        );
      })
    )
  );

  addDocumentComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(commentsActions.addDocumentComment),
      withLatestFrom(this.userAuthFacade.myself$),
      switchMap(([{ comment, userProcessId }, myself]) => {
        let stream: Observable<DocumentComment>;
        const userRoleKey = myself.role.key as ROLES_KEYS;

        if (userRoleKey === ROLES_KEYS.Employer) {
          throwError(() => new Error('NOT_ALLOWED'));
        }

        if (userRoleKey === ROLES_KEYS.Foreigner) {
          stream = this.commentsHttp.addDocumentCommentForeigner(userProcessId, comment);
        }

        if (highEmployeeRoles.includes(userRoleKey)) {
          stream = this.commentsHttp.addDocumentCommentEmployee(comment, userProcessId);
        }

        return stream.pipe(
          map(createdDocumentComment => {
            return commentsActions.addDocumentCommentSuccess({ createdDocumentComment, userProcessId });
          }),
          catchError(error => {
            const errorMessage = parseError(error, commentsActions.addDocumentComment.type);
            return of(commentsActions.addDocumentCommentError({ errorMessage }));
          })
        );
      })
    )
  );

  editDocumentComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(commentsActions.editDocumentComment),
      withLatestFrom(this.userAuthFacade.myself$),
      switchMap(([{ comment, userProcessId }, myself]) => {
        const usersAllowedToEditComments = [...highEmployeeRoles];
        const userRoleKey = myself.role.key as ROLES_KEYS;

        if (!usersAllowedToEditComments.includes(userRoleKey)) {
          return throwError(() => new Error('NOT_ALLOWED_TO_EDIT_COMMENT'));
        }

        return this.commentsHttp.editDocumentCommentEmployee(comment, userProcessId).pipe(
          map(editedDocumentComment => {
            return commentsActions.editDocumentCommentSuccess({ editedDocumentComment, userProcessId });
          }),
          catchError(error => {
            const errorMessage = parseError(error, commentsActions.editDocumentComment.type);
            return of(commentsActions.editDocumentCommentError({ errorMessage }));
          })
        );
      })
    )
  );

  removeDocumentComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(commentsActions.removeDocumentComment),
      withLatestFrom(this.userAuthFacade.myself$),
      switchMap(([{ comment, userProcessId }, myself]) => {
        // Foreigners are not allowed to remove comments!
        if (myself.role.key === ROLES_KEYS.Foreigner) {
          return throwError(() => new Error('NOT_ALLOWED'));
        }

        return this.commentsHttp.removeDocumentCommentEmployee(comment, userProcessId).pipe(
          map(() => {
            return commentsActions.removeDocumentCommentSuccess({
              removedDocumentCommentId: comment.id,
              userProcessId,
            });
          }),
          catchError(error => {
            const errorMessage = parseError(error, commentsActions.removeDocumentComment.type);
            return of(commentsActions.removeDocumentCommentError({ errorMessage }));
          })
        );
      })
    )
  );

  markDocumentCommentsAsRead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(commentsActions.markDocumentCommentsAsRead),
      switchMap(({ userDocumentId, userProcessId }) => {
        return this.commentsHttp.markDocumentCommentsAsRead(userDocumentId, userProcessId).pipe(
          map(({ comments }) => {
            return commentsActions.markDocumentCommentsAsReadSuccess({
              userDocumentId,
              userProcessId,
              updatedComments: comments,
            });
          }),
          catchError(error => {
            const errorMessage = parseError(error, commentsActions.markDocumentCommentsAsRead.type);
            return of(commentsActions.markDocumentCommentsAsReadError({ errorMessage }));
          })
        );
      })
    )
  );

  localStateUpdateAfterMarkingCommentsAsRead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(commentsActions.markDocumentCommentsAsReadSuccess),
      withLatestFrom(this.userAuthFacade.myself$),
      switchMap(([{ userDocumentId, userProcessId, updatedComments }, { id: readerId }]) => {
        return of(
          commentsActions.readerAnyNewCommentsForceCheck({
            readerId,
            userProcessId,
            userDocumentId,
            updatedComments,
          })
        );
      })
    )
  );
}
