import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { DiscountCodesHttpService } from '@core/http/discount-codes.http.service';
import { SnackbarService } from '@shared/snack-bar/snack-bar.service';
import * as dcActions from './discount-codes.actions';

@Injectable()
export class DiscountCodesEffects {
  constructor(
    private actions$: Actions,
    private http: DiscountCodesHttpService,
    private snackService: SnackbarService
  ) {}

  getAllDiscountCodes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dcActions.getAllDiscountCodes),
      switchMap(({ showInactive }) => {
        return this.http.getAllDiscountCodes(showInactive).pipe(
          map(discountCodes => dcActions.getAllDiscountCodesSuccess({ discountCodes })),
          catchError(() => {
            this.snackService.showError('ADMINISTRATION.DC.ERROR_DOWNLODING_DISCOUNT_CODES_DATA');
            return of(dcActions.getAllDiscountCodesError());
          })
        );
      })
    )
  );

  createDiscountCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dcActions.createDiscountCode),
      switchMap(({ opts }) => {
        return this.http.createDiscountCode(opts).pipe(
          map(discountCode => dcActions.createDiscountCodeSuccess({ discountCode })),
          catchError(() => {
            this.snackService.showError('ADMINISTRATION.DC.ERROR_CREATING_DISCOUNT_CODE');
            return of(dcActions.createDiscountCodeError());
          })
        );
      })
    )
  );

  editDiscountCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dcActions.editDiscountCode),
      switchMap(({ opts }) => {
        return this.http.editDiscountCode(opts).pipe(
          map(discountCode => dcActions.editDiscountCodeSuccess({ discountCode })),
          catchError(() => {
            this.snackService.showError('ADMINISTRATION.DC.ERROR_EDITING_DISCOUNT_CODE');
            return of(dcActions.editDiscountCodeError());
          })
        );
      })
    )
  );

  setActiveDiscountCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dcActions.setActiveDiscountCode),
      switchMap(({ discountCodeId, setActive }) => {
        return this.http.setActiveDiscountCode(discountCodeId, setActive).pipe(
          map(discountCode => dcActions.setActiveDiscountCodeSuccess({ discountCode })),
          catchError(() => {
            this.snackService.showError('ADMINISTRATION.DC.ERROR_CHANGING_DISCOUNT_CODE');
            return of(dcActions.setActiveDiscountCodeError());
          })
        );
      })
    )
  );
}
