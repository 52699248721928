import { CreateDiscountCodeOpts, DiscountCode, EditDiscountCodeOpts } from '@interfaces';
import { createAction, props } from '@ngrx/store';

export const getAllDiscountCodes = createAction(
  '[Discount Codes] Get All Discount Codes',
  props<{ showInactive?: boolean }>()
);
export const getAllDiscountCodesSuccess = createAction(
  '[Discount Codes] Get All Discount Codes Success',
  props<{ discountCodes: DiscountCode[] }>()
);
export const getAllDiscountCodesError = createAction('[Discount Codes] Get All Discount Codes Error');

export const createDiscountCode = createAction(
  '[Discount Codes] Create Discount Code',
  props<{ opts: CreateDiscountCodeOpts }>()
);
export const createDiscountCodeSuccess = createAction(
  '[Discount Codes] Create Discount Code Success',
  props<{ discountCode: DiscountCode }>()
);
export const createDiscountCodeError = createAction('[Discount Codes] Create Discount Code Error');

export const editDiscountCode = createAction(
  '[Discount Codes] Edit Discount Code',
  props<{ opts: EditDiscountCodeOpts }>()
);
export const editDiscountCodeSuccess = createAction(
  '[Discount Codes] Edit Discount Code Success',
  props<{ discountCode: DiscountCode }>()
);
export const editDiscountCodeError = createAction('[Discount Codes] Edit Discount Code Error');

export const setActiveDiscountCode = createAction(
  '[Discount Codes] Set active discount code',
  props<{ discountCodeId: string; setActive: 'activate' | 'deactivate' }>()
);
export const setActiveDiscountCodeSuccess = createAction(
  '[Discount Codes] Set active discount code Success',
  props<{ discountCode: DiscountCode }>()
);
export const setActiveDiscountCodeError = createAction('[Discount Codes] Set active discount code Error');
