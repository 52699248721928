<div class="page-wrapper">
  <div class="back-button">
    <button mat-button (click)="goBack()">
      <mat-icon>chevron_left</mat-icon>
      <span>{{ 'COMMON.BACK' | translate }}</span>
    </button>
  </div>

  <div *ngIf="myself$ | async as myself" class="user-card">
    <div class="form-container">
      <!-- Title -->
      <div class="page-title">
        <h1>{{ 'PROFILE.PROFILE' | translate }}</h1>
      </div>

      <!-- Avatar -->
      <div class="avatar-container">
        <img
          src="/assets/img/avatar-placeholder.png"
          *ngIf="!myself.avatarUpdatedAt && (loading$ | async) === false"
          alt="User Avatar" />
        <img
          [src]="API_URL + '/user-assets/avatar/' + myself.id + '?uncache=' + uncacheParam"
          *ngIf="myself.avatarUpdatedAt && (loading$ | async) === false"
          alt="User Avatar" />
        <div class="spinner-container" *ngIf="loading$ | async">
          <mat-spinner [diameter]="50"></mat-spinner>
        </div>
        <button nz-button (click)="regenerateInitialsAvatar()" nzType="primary" [nzSize]="'small'" nzShape="round">
          <span nz-icon nzType="reload" nzTheme="outline"></span>
        </button>
      </div>

      <form nz-form [formGroup]="form">
        <!-- USERNAME -->
        <nz-form-item>
          <nz-form-label [nzSm]="12" [nzXs]="24" nzRequired nzFor="name">
            {{ 'LABELS.USERNAME' | translate }}
          </nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24">
            <input nz-input id="name" type="text" formControlName="name" required />
          </nz-form-control>
        </nz-form-item>

        <!-- LANGUAGE -->
        <nz-form-item *ngIf="languages$ | async as languages">
          <nz-form-label [nzSm]="12" [nzXs]="24" nzRequired nzFor="name">
            {{ 'LANGUAGE.LAYOUT_LANGUAGE' | translate }}
          </nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24">
            <nz-select
              id="language"
              *ngIf="languages$ | async as languages"
              formControlName="languageId"
              [nzPlaceHolder]="'LANGUAGE.LAYOUT_LANGUAGE' | translate"
              [required]="true">
              <nz-option
                *ngFor="let lang of languages"
                [nzValue]="lang.id"
                [nzLabel]="'LANGUAGE.' + lang.key.toUpperCase() | translate">
              </nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>

        <!-- EMAIL -->
        <nz-form-item *ngIf="(myself$ | async)?.role?.key !== 'FOREIGNER'">
          <nz-form-label [nzSm]="12" [nzXs]="24" nzRequired nzFor="email">
            {{ 'LABELS.EMAIL' | translate }}
          </nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24">
            <input nz-input id="email" type="text" formControlName="email" required />
          </nz-form-control>
        </nz-form-item>

        <!-- PHONE NUMBER -->
        <nz-form-item>
          <nz-form-label [nzSm]="12" [nzXs]="24" nzRequired nzFor="telephoneNumber">
            {{ 'LABELS.MOBILE' | translate }}
          </nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24">
            <input nz-input id="telephoneNumber" type="text" formControlName="telephoneNumber" required />
          </nz-form-control>
        </nz-form-item>

        <!-- OFFICE -->
        <nz-form-item *ngIf="shouldShowOfficeField && (offices$ | async)?.length">
          <nz-form-label [nzSm]="12" [nzXs]="24" nzRequired nzFor="officeId">
            {{ 'NT.ASSIGNED_OFFICE' | translate }}
          </nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24">
            <nz-select
              id="officeId"
              *ngIf="shouldShowOfficeField && (offices$ | async)?.length"
              formControlName="officeId"
              [nzPlaceHolder]="'NT.ASSIGNED_OFFICE' | translate"
              [required]="true">
              <nz-option
                *ngFor="let office of offices$ | async"
                [nzValue]="office.id"
                [nzLabel]="office.addressCity + ' (' + office.addressStreet + ')'">
              </nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>

        <div class="form-column submit-column">
          <button
            (click)="submitProfileForm()"
            [disabled]="(form.dirty && form.invalid) || form.pristine || (loading$ | async)"
            mat-raised-button
            class="submit-button"
            color="primary">
            {{ 'COMMON.UPDATE' | translate }}
          </button>
          <button (click)="deleteAccount()" mat-raised-button class="submit-button" color="warn">
            {{ 'COMMON.REMOVE' | translate }}
          </button>
          <div class="indicator-wrapper">
            <mat-spinner [diameter]="45" *ngIf="loading$ | async"></mat-spinner>
            <mat-icon *ngIf="form.pristine && (updateSuccess$ | async) && (loading$ | async) === false"
              >check
            </mat-icon>
          </div>
        </div>
      </form>
    </div>

    <div class="purchases-list-container" *ngIf="showPurchaseList">
      <div class="header-divider">
        <nz-divider nzText="{{ 'PURCHASE.PURCHASES_LIST' | translate }}" nzOrientation="center"></nz-divider>
      </div>
      <div class="purchases-list" *ngIf="(loadingPurchaseList$ | async) === false && (purchaseList$ | async)?.length">
        <nz-collapse>
          <nz-collapse-panel
            *ngFor="let purchase of purchaseList$ | async; index as purchaseIndex"
            [nzHeader]="PurchaseHeader">
            <ng-template #PurchaseHeader>
              <span>
                {{ 'PURCHASE.TRANSACTION_DATE' | translate }}
                <b>{{ purchase.updatedAt | date: 'YYYY-MM-dd HH:mm' }}</b>
              </span>
              &nbsp; &nbsp;
              <span *ngIf="purchase.status === PurchaseStatus.CLOSED">
                {{ 'PURCHASE.TOTAL_AMOUNT_PAID' | translate }}
                <b>{{ (purchase.totalAmountPaid / 100).toFixed(2) }} PLN</b>
              </span>
              <span *ngIf="purchase.status !== PurchaseStatus.CLOSED">
                {{ 'PURCHASE.TRANSACTION_NOT_FINISHED' | translate }}
              </span>
            </ng-template>
            <ul *ngIf="purchase.paymentInfo.p24_transactionInfo as transactionInfo">
              <li class="app-list-item">
                <b>{{ 'PURCHASE.TRANSACTION_STATEMENT_VISIBLE_IN_BANK_ACCOUNT_HISTORY' | translate }}</b>
              </li>
              <li class="app-list-item">
                {{ transactionInfo.statement }}
              </li>
              <li class="app-list-item">
                <b>{{ 'PURCHASE.TRANSACTION_AMOUNT' | translate }}</b>
              </li>
              <li class="app-list-item">
                {{ (purchase.totalAmountPaid / 100).toFixed(2) }}
              </li>
              <li class="app-list-item">
                <b>{{ 'PURCHASE.TRANSACTION_CURRENCY' | translate }}</b>
              </li>
              <li class="app-list-item">
                {{ purchase.paymentInfo.currency }}
              </li>
            </ul>
            <ul *ngIf="purchase.products?.length">
              <li class="app-list-item">
                <b>{{ 'PURCHASE.PURCHASED_SERVICES' | translate }}</b>
              </li>
              <li class="app-list-item" *ngFor="let product of purchase.products">
                {{ product.name[currLang] }} x{{ product.quantity }}
              </li>
            </ul>
            <ul *ngIf="purchase.userProcesses?.length">
              <li class="app-list-item">
                <b>{{ 'PURCHASE.SERVICES_IN_PROGRESS' | translate }}</b>
              </li>
              <li class="app-list-item" *ngFor="let app of purchase.userProcesses">
                <a class="in-app-link" [routerLink]="['/user-process', app.id]">
                  {{ 'APPLICATION.APPLICATION' | translate }}
                  {{ app.name }}
                  <ng-container *ngIf="app.personalDetails?.name || app.personalDetails?.surname">
                    -
                    {{ app.personalDetails.name }}
                    {{ app.personalDetails.surname }}
                  </ng-container>
                </a>
                &nbsp;-&nbsp;
                <app-service-level-tag [userProcess]="app" [mode]="PurchaseTags.LARGE"></app-service-level-tag>
              </li>
              <li class="app-list-item">
                <button
                  nz-button
                  nzType="primary"
                  *ngIf="purchase.purchaseDocumentId"
                  (click)="downloadPurchaseDocument(purchase.purchaseDocumentId)">
                  {{ 'PURCHASE.DOWNLOAD_PURCHASE_DOCUMENT' | translate }}
                </button>
                <span *ngIf="!purchase.purchaseDocumentId">
                  {{ 'PURCHASE.PURCHASE_DOCUMENT_NOT_AVAILABLE' | translate }}
                </span>
              </li>
            </ul>
          </nz-collapse-panel>
        </nz-collapse>
      </div>
      <div
        class="empty-purchases-list"
        *ngIf="(loadingPurchaseList$ | async) === false && (purchaseList$ | async)?.length === 0">
        <nz-empty></nz-empty>
      </div>
      <div class="loading-container-in-section" *ngIf="(loadingPurchaseList$ | async) === true">
        <mat-spinner [diameter]="50"></mat-spinner>
        <div class="spinner-message" [innerHTML]="'PURCHASE.LOADING_PURCHASE_HISTORY' | translate"></div>
      </div>
    </div>
  </div>
</div>
