<div *ngIf="myself$ | async as myself">
  <div class="application-select-wrapper" *ngIf="myProcesses$ | async as myProcesses">
    <div class="standard-form-field">
      <mat-form-field appearance="outline">
        <mat-select
          appMatOptionHeight
          [appMatOptionHeightValue]="125"
          panelClass="main-applications-select"
          id="applicationSelect"
          (selectionChange)="applicationChanged.emit($event)"
          [compareWith]="compareSelectedApplication"
          [value]="myProcess">
          <mat-select-trigger>
            <div class="select-trigger-text-wrapper" *ngIf="!addingNewProcess && myProcess as selectedProcess">
              <span class="user-process-selected-option-label">
                {{ 'APPLICATION.CURRENTLY_SELECTED_APPLICATION' | translate }}
              </span>
              <span class="user-process-selected-option">
                {{ 'APPLICATION.APPLICATION' | translate }} {{ selectedProcess.name }}
                <ng-container *ngIf="selectedProcess.personalDetails?.name || selectedProcess.personalDetails?.surname">
                  -
                </ng-container>
                <ng-container *ngIf="selectedProcess.personalDetails?.name as name">
                  {{ name }}
                </ng-container>
                <ng-container *ngIf="selectedProcess.personalDetails?.surname as surname">
                  {{ surname }}
                </ng-container>
              </span>
            </div>
            <div class="select-trigger-text-wrapper" *ngIf="addingNewProcess?.id === 'new'">
              <span class="user-process-selected-option-label">
                {{ 'APPLICATION.CURRENTLY_SELECTED_APPLICATION' | translate }}
              </span>
              <span class="user-process-selected-option">
                {{ 'APPLICATION.NEW_APPLICATION' | translate }}
              </span>
            </div>
            <div class="select-trigger-text-wrapper" *ngIf="addingNewProcess?.id === 'purchase'">
              <span class="user-process-selected-option-label">
                {{ 'APPLICATION.CURRENTLY_SELECTED_APPLICATION' | translate }}
              </span>
              <span class="user-process-selected-option">
                {{ 'PURCHASE.IN_SERVICE_PUCHASING' | translate }}
              </span>
            </div>
          </mat-select-trigger>
          <mat-option *ngFor="let userProcess of myProcesses" [value]="userProcess">
            <div class="applications-select-option-content-wrapper">
              <div class="select-item-column select-item-column-icon">
                <mat-icon *ngIf="!addingNewProcess && userProcess.id === myProcess?.id">
                  radio_button_checked
                </mat-icon>
                <mat-icon *ngIf="addingNewProcess || userProcess.id !== myProcess?.id">
                  radio_button_unchecked
                </mat-icon>
              </div>
              <div class="select-item-column select-item-column-data">
                <div class="main-label">
                  {{ 'APPLICATION.APPLICATION' | translate }} {{ userProcess.name }}
                  <ng-container *ngIf="userProcess.personalDetails?.name || userProcess.personalDetails?.surname">
                    -
                  </ng-container>
                  <ng-container *ngIf="userProcess.personalDetails?.name as name">
                    {{ name }}
                  </ng-container>
                  <ng-container *ngIf="userProcess.personalDetails?.surname as surname">
                    {{ surname }}
                  </ng-container>
                </div>
                <div
                  class="status-label"
                  [class.negative]="userProcess.status === 'CHANGES_REQUESTED'"
                  [class.positive]="
                    userProcess.status === 'OFFICE_VISIT_SCHEDULED' || userProcess.status === 'APPLICATION_SUBMITTED'
                  ">
                  <app-user-process-status [userProcess]="userProcess"></app-user-process-status>
                </div>
                <div class="sub-label" *ngIf="userProcess.process?.category">
                  {{ 'PROCESS_CATEGORY.' + userProcess.process.category | translate | truncate: 100 : false }}
                </div>
                <!-- only for purchased processes -->
                <div class="sub-label services">
                  <app-service-level-tag
                    [userProcess]="userProcess"
                    [mode]="PurchaseTags.LARGE"></app-service-level-tag>
                </div>
              </div>
            </div>
          </mat-option>
          <mat-option
            *ngIf="myself.allowedToAddOwnUserProcesses && myself.userProcessesLimit > myProcesses?.length"
            [value]="{ id: 'new' }">
            <div class="applications-select-option-content-wrapper">
              <div class="select-item-column select-item-column-icon">
                <mat-icon>add</mat-icon>
                <!-- <mat-icon *ngIf="addingNewProcess?.id !== 'new'">radio_button_unchecked</mat-icon> -->
              </div>
              <div class="select-item-column select-item-column-data">
                <div class="main-label">{{ 'APPLICATION.NEW_APPLICATION' | translate }}</div>
                <div class="status-label">{{ 'APPLICATION.ADD_NEW_APPLICATION' | translate }}</div>
              </div>
            </div>
          </mat-option>
          <mat-option
            *ngIf="
              myself.company.purchaseEnabled &&
              (!myself.allowedToAddOwnUserProcesses || myself.userProcessesLimit <= myProcesses?.length)
            "
            [value]="{ id: 'purchase' }">
            <div class="applications-select-option-content-wrapper">
              <div class="select-item-column select-item-column-icon">
                <mat-icon>add</mat-icon>
              </div>
              <div class="select-item-column select-item-column-data">
                <div class="main-label">{{ 'PURCHASE.IN_SERVICE_PUCHASING' | translate }}</div>
                <div class="status-label">
                  {{ 'PURCHASE.CLICK_HERE_TO_PURCHASE_NEXT_APPLICATIONS' | translate }}
                </div>
              </div>
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>
