import { DiscountCode } from '@interfaces';

export type DiscountCodesState = Readonly<{
  loading: boolean;
  allDiscountCodes: DiscountCode[];
}>;

export const initialState: DiscountCodesState = {
  loading: false,
  allDiscountCodes: [],
};
